.title {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  color: #4a4a4a;
}

.subsriptionsWrapper {
  width: 100%;
}

.subscriptionCard {
  width: 100%;
  margin-bottom: 3pc;
}

.expandedInfo {
  display: flex;
  flex-direction: column;
}

.addSubscriptionButton {
  margin-bottom: 1pc;
  text-decoration: none !important;
  margin-right: 2pc;
}

.sortSubscriptionButton {
  text-decoration: none !important;
}

.addButton {
  padding: 0 15px;
  min-height: 3pc;
}

.sortButton {
  padding: 0 15px;
  min-height: 3pc;
}

.content {
  width: 100%;
}

.planOption {
  width: 20pc;
  margin-bottom: 2pc;
}

.warning {
  color: red;
}

.buttonsWrapper {
  display: flex;
  width: auto;
}

.whiteText {
  color: #ffffff;
}

.cardElementWrapper {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 20px 10px;
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--colorGrey100);
  border-top-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (--viewportLarge) {
    flex-wrap: unset;
    padding: 40px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 40px 0 82px calc((100% - 1056px) / 2);
  }
}