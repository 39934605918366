.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.item {
    box-shadow: var(--boxShadow);
    border-radius: 15px;
    transition: background-color 0.3s ease;

}

.active {
    background-color: #F3EBFE;
}

.question {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 36px;
    align-items: center;
    padding: 0 16px;
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0 16px;
    margin: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
}

.active .answer {
    /* max-height will be dynamically set via JS */
    overflow: visible;
    margin-bottom: 16px;
    opacity: 1;
}

.arrow {
    transition: transform 0.3s ease-in-out;
}

.arrowRotated {
    transition: transform 0.3s ease-in-out;
    transform: rotate(180deg);
}