 @import '../../styles/customMediaQueries.css';

 .root {
     display: flex;
     flex-direction: column;
     gap: 10px;
 }

 .chipsContainer {
     align-items: center;
     display: grid;
     grid-template-columns: 1fr 1fr;
     gap: 12px;
     flex-wrap: wrap;

     @media (--viewportLarge) {
         grid-template-columns: 1fr 1fr 1fr;
         gap: 20px;
     }
 }

 .fieldLabel {
     display: flex;
     align-items: center;
     padding: 0;
     flex: 1;
 }

 .chip {
     display: flex;
     justify-content: center;
     text-align: center;
     align-items: center;
     border-radius: var(--borderRadiusMedium);
     transition: var(--transitionStyleButton);
     cursor: pointer;
     padding: 10px 24px;
     text-decoration: none;
     /* color: var(--colorGrey700); */
     color: #5E5E62;
     background-color: #E5E5E6;

     &:hover {
         /* background-color: var(--marketplaceColor); */
         color: var(--colorWhite);
         text-decoration: none;
     }
 }

 .chipLabel {
     cursor: pointer;
     font-size: 13px;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0;
     margin: 0;
     font-weight: var(--fontWeightSemiBold);
 }

 .chipSelected {
     composes: chip;
     border: solid 5px var(--marketplaceColor);
     color: var(--colorWhite);
 }

 .listing {
     width: 100%
 }

 .ctaButton {
     display: flex;
     gap: 10px;
     flex-direction: row;
     width: fit-content;
     text-align: center;
     color: var(--colorWhite);
     text-decoration: none;
     height: 48px;
     align-items: center;
     font-weight: normal;
     font-size: 14px;
     padding: 12px 16px;
     border-radius: 4px;
     background-color: var(--marketplaceColor);
     transition: var(--transitionStyleButton);

     &:hover {
         background-color: var(--marketplaceColorDark);
         text-decoration: none;
     }
 }