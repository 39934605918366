.calendar {
    display: inline-block;
    margin: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    /* height: 320px; */
    max-height: 320px;
}

.calendar-grid {
    display: grid;
    margin-top: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}

.calendar-header {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.days-of-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.days-grid {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
}

.day {
    position: relative;
    /* padding: 4px; */
    cursor: pointer;
    font-size: 13px;
    line-height: 100%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.day.empty {
    /* background-color: #f0f0f0; */
}

.day.available {
    background-color: green;
    border: solid 1px var(--marketplaceColor);
    color: white
}

.day.unavailable {
    /* background-color: #f8d7da; */
}


.day .cross {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-weight: bold;
    font-size: 18px;
    pointer-events: none;
}