@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.locationAutocompleteInput {}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 32px;
  }
}


.dropdown {
  margin-top: 6px;
  display: inline-block;
  min-width: 350px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    justify-content: flex-start;
    width: 450px;
  }
}

.dropdownButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  border-radius: var(--borderRadiusFull);
  border-bottom: 1px solid #d8dce6;
  padding: 8px 0;
  cursor: pointer;
  margin-bottom: 5px;

  >label {
    padding: 0;
  }

  @media (--viewportMedium) {
    /* justify-content: flex-start; */
  }
}

.dropdownContent {
  display: block;
  position: absolute;
  width: calc(100vw - 48px);
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  border: 1px solid #d8dce6;
  padding: 8px 16px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  max-height: 400px;
  scrollbar-color: var(--colorGold) var(--colorAttentionLight);

  @media (--viewportMedium) {
    width: 450px;
  }
}

.arrow {
  transition: transform 0.3s ease-in-out;
}

.arrowRotated {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);
}