@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}


.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 32px;
  }
}


.info {
  font-weight: 400;
}

.deleteButton {
  color: red;
  margin-top: 30px;

  @media (--viewportMedium) {
    margin-top: 40px;
  }
}