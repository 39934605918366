@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingBreakdownTitle {
  letter-spacing: 1px;
  font-weight: var(--fontWeightMedium);
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.bookingDates {
  flex-shrink: 0;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.error {
  color: var(--colorFail);
  margin: 0 24px;
  display: inline-block;
}

.sideBarError {
  composes: h4 from global;
  color: var(--colorFail);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 12px 0;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }
}

.arrowIcon {
  stroke: var(--colorWhite);
  fill: var(--colorWhite);
}
