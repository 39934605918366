.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Optional: allows wrapping to the next line if there are too many items */
    gap: 16px; /* Optional: adds space between the items */
  }
  
  .planOption {
    flex: 1 1 auto; /* Allows the items to grow and shrink as needed */
    min-width: 200px; /* Optional: sets a minimum width for the items */
    /* Add any other styling you need for the plan options */
  }