@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.listingTypeSelect {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.selectedLabel {
  composes: label from global;
}

.selectedValue {
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px 0 6px 0;
  }
}

.unitTypeHidden {
  display: none;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin: 32px 0;
  }
}


.imagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.imageContainer {
  position: relative;
  width: 200px;
  height: 200px;
}

.photoTip {
  color: var(--colorGrey300);
  font-size: 12px;
}



.charactersCount {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
  color: darkgray;
  font-size: 12px;
  font-weight: 400;
  margin-top: -30px;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
  align-items: end;
}

.ctaButton {
  display: flex;
  flex-direction: row;
  width: fit-content;
  text-align: center;
  color: var(--colorWhite);
  text-decoration: none;
  height: 48px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: var(--marketplaceColor);
  transition: var(--transitionStyleButton);
  margin-bottom: 32px;

  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}

.icon {
  margin-left: 10px;
}