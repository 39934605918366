@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.petsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px 24px;
  margin-bottom: 32px;
}

.petData {
  border: 1px solid rgba(216, 220, 230, 1);
  padding: 8px 12px;
  border-radius: 4px;

  &:hover {
    border: 1px solid rgba(116, 177, 195, 1);
    background-color: rgba(197, 255, 194, 0.2);
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 32px;
  }
}