@import '../../../../../styles/customMediaQueries.css';

.root {
    border: 1px solid rgba(216, 220, 230, 1);
    padding: 0 16px 24px 16px;
    border-radius: 8px;
    box-shadow: var(--boxShadowPopupLight);
    transition: var(--transitionStyleButton);
    margin-bottom: 32px;

    &:hover {
        border: 1px solid rgba(116, 177, 195, 1);
        box-shadow: var(--boxShadowPopup);
    }
}

.title {
    height: 50px;
    padding: 0 0 0 16px;
    margin: 0 -16px;
    display: flex;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgba(124, 56, 139, 0.1);
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.nameContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-top: 16px;
}

.name {
    width: 85%
}

.age {
    width: 15%
}

.fieldClass {
    margin-top: 0
}

.imageContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 12px 24px;
}

.imageContainer>div {
    aspect-ratio: 1 / 1;
}

.arrayError {
    composes: h4 from global;
    margin: 0;
    color: var(--colorFail);
    overflow: hidden;
    font-size: 13px;
}

.isCompleted {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    background-color: hsl(117, 100%, 94.5%);

    >p {
        padding: 0;
        margin: 0 8px;
    }
}

.editButton {
    width: fit-content;
    padding: 8px 16px;
    height: 36px;
    min-height: unset;
    line-height: 16px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.saveButton {
    width: fit-content;
    padding: 8px 24px;
    line-height: 16px;
}