@import '../../../../../styles/customMediaQueries.css';

.root {
    /* Dimensions */
    width: 100%;
    height: auto;

    /* Layout */
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.subheading {
    composes: p from global;
}

.heading,
.subheading,
.timezonePicker,
.submitButton {
    /* padding-left: 30px; */
    /* padding-right: 30px; */

    @media (--viewportSmall) {
        /* padding-left: 60px; */
        /* padding-right: 60px; */
    }
}

.subheading {
    margin: 0 0 6px 0;
    padding-top: 19px;
    padding-bottom: 5px;

    @media (--viewportMedium) {
        margin: 0 0 8px 0;
    }
}

.error {
    color: var(--colorFail);
}

.timezonePicker {
    margin-bottom: 24px;
}

.week {
    display: flex;
    flex-direction: column;

    padding-top: 16px;
    margin-bottom: 23px;

    @media (--viewportLarge) {
        margin-bottom: 95px;
    }
}

.saveScheduleButton {
    flex-shrink: 0;
    min-width: 200px;

    @media (--viewportMedium) {
        padding-bottom: 0;
        align-self: center;
    }
}

.submitButton {
    margin-top: auto;
    margin-bottom: 24px;
    flex-shrink: 0;

    @media (--viewportLarge) {
        display: inline-block;
        width: 241px;
        margin-top: 32px;
        align-self: flex-end;
    }
}

.linkClass {
    background-color: transparent;
    color: var(--colorWhite);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
        text-decoration: none;
    }
}

.infoContainer {
    margin-bottom: 20px;
}

.info {
    padding: 0;
    margin: 0;
}