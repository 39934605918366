.root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >label {
        padding: 0;
    }
}

.numberInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .display {
        height: fit-content;
        min-width: 24px;
        text-align: center;
    }
}

.button {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: rgba(116, 177, 195, 0.3);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button:active {
    background-color: rgba(116, 177, 195, 0.6);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}