@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
}

.grid2Columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px 24px;
  align-items: stretch;
  grid-auto-rows: 1fr;
}

.inactiveItem {
  border: 1px solid var(--colorGrey200);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: var(--borderRadiusMedium);
  min-height: 48px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  background-color: var(--colorWhite);

  >label {
    cursor: pointer;
    height: 48px;
    width: 100%;
    padding: 0 12px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
    color: var(--colorGrey700);
  }

  &:hover {
    border: 1px solid #74B1C3;
    background-color: var(--marketplaceSecondaryColorDark);
  }
}

.activeItem {
  composes: inactiveItem;
  background-color: var(--marketplaceSecondaryColor);
}